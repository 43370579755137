import { render, staticRenderFns } from "./GssiAdmin.vue?vue&type=template&id=744c3d96&scoped=true"
import script from "./GssiAdmin.vue?vue&type=script&lang=ts"
export * from "./GssiAdmin.vue?vue&type=script&lang=ts"
import style0 from "./GssiAdmin.vue?vue&type=style&index=0&id=744c3d96&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "744c3d96",
  null
  
)

export default component.exports