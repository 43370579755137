
import { companies, CompanyStatus } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "GssiAdmin",
  components: {},
  data() {
    return {};
  },
  computed: {
    companyIdParam: function (): number {
      return Number(this.$route.params.companyId);
    },
    companyStatus: function (): string {
      return CompanyStatus[this.$store.state.company?.account?.cao?.company?.status];
    },
    showBanner: function ():boolean {
      return !!(this.companyIdParam && !this.$route.path.includes("/billingedit"))
    }
  },
  methods: {},
  watch: {
    companyIdParam: {
      async handler() {
        // console.log("changed", this.companyIdParam);
        //gssi user
        if (!this.companyIdParam) {
          await this.$store.dispatch(
            Actions.GetCompany,
            this.$store.state.associate.company
          );
        } else {
          const company = (await companies.get(this.companyIdParam)).data[0];
          await this.$store.dispatch(Actions.GetCompany, company);
        }
      },
      immediate: true,
    },
  },
});
